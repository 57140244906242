import React from "react"
import { Link } from "gatsby"

import { Nav, NavItem, NavLink } from 'reactstrap';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Teamimage from "./../images/0f6dee8c-c190-4c38-bfb5-06acb0fe88d8.jpg"

const SecondPage = () => (
  <Layout>
    <SEO title="About us" />
    <section
      className="section-header text-white overlay-soft-primary"
      style={{
        backgroundImage: "url(" + Teamimage + ")",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <div className="container">
        <div className="row min-vh-50 justify-content-center text-center align-items-center">
          <div className="col-xl-8 col-lg-9 col-md-10">
            <h1 className="display-1">About us</h1>
          </div>
        </div>
      </div>
    </section>
    <section>
      <Nav className="py-4">
        <NavItem>
          <NavLink href="#">Background</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#goals">Goals</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Mission and Vision</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Our values</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Our Partners</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Our Priorities</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Our approach</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="#">Success stories</NavLink>
        </NavItem>
      </Nav>
    </section>
    <section className="section section-lg pt-6 bg-light">
      <div className="container">
        <div className="row justify-content-center mb-5 mb-md-4">
          <div className="col-12 col-md-8 text-center">
            <h4 className="h1 font-weight-bolder mb-4">
              <span className="text-primary">R</span>WANDA{" "}
              <span className="text-secondary">H</span>EALTH{" "}
              <span className="text-warning">I</span>NITIATIVE FOR{" "}
              <span className="text-info">Y</span>OUTH AND{" "}
              <span className="text-danger">W</span>OMEN (RHIYW)
            </h4>
            <p className="lead">
              Rwanda Health Initiative for youth and women is a non-governmental, non-profit organization focusing
              specifically on improving the lives of adolescents, young people and women.
            </p>
          </div>
        </div>
        <div
          id="goals"
          className="row row-grid align-items-center justify-content-between mb-2 mt-2 mb-md-2"
        >
          <div className="col-12 col-md-12">
            {/* Header section with green background */}
            <h2 className="font-weight-bolder mb-4">
              HISTORY AND OVERVIEW
            </h2>
            <p className="lead">
              RHIYW is a Rwandan non-governmental organization born out of the passion and involvement of clinicians
              (nurses, midwives, and medical doctors), healthcare advocates, public health experts, and youth SRH
              champions. The organization aims to ensure adolescents, young people, and women live healthy lives by
              empowering individuals with the knowledge they need to make informed decisions about their reproductive
              health. RHIYW ensures that high-quality reproductive health services are available and accessible to all
              youth and women, including services related to adolescent sexual and reproductive health, menstrual
              health, maternal health, contraception, support for survivors of gender-based violence, and prevention and
              management of sexually transmitted infections.
            </p>
            <p className="lead">
              RHIYW is committed to fostering a supportive environment where every young person and woman can access the
              sexual and reproductive healthcare they need, advancing a healthier future. In collaboration with its
              partners, the organization implements a range of interventions in sexual and reproductive health, focusing
              on adolescents and maternal health.
            </p>
            <p className="lead">
              The organization employs efficient and sustainable strategies to minimize impacts and promptly reduce
              health risks associated with unintended pregnancies, unsafe abortions, gender-based and sexual violence,
              inadequate antenatal, perinatal, and postnatal care, and sexually transmitted diseases.
            </p>
            <p className="lead">
              RHIYW works closely with public health institutions, primarily the Rwanda Ministry of Health/Rwanda
              Biomedical Center, and other national and international organizations dedicated to healthcare, health
              promotion, human rights, and social justice.
            </p>
            <p className="lead">
              In addition to a local team of public health experts, gynecologists, medical doctors, nurses, midwives,
              mental health specialists, social workers, and youth champions for sexual and reproductive health and
              rights (SRHR), RHIYW's advisory committee comprises global experts in SRHR, public health, and advocacy.
            </p>
            <p className="lead">
              RHIYW operates in several districts in Rwanda and has two offices: a satellite office in Musanze District
              in the Northern Province and a headquarters office in Kicukiro District of Kigali.
            </p>
          </div>
        </div>

        <br/>
        <div className="row align-items-center">
          <div className="col-12 col-md-12">

            {/* Section Heading */}
            <h2 className="font-weight-bolder mb-4">RHIYW's Vision, Mission, and Values</h2>

            {/* Vision */}
            <h3 className="font-weight-bold mb-2">Vision</h3>
            <p className="lead">
              To promote the health and well-being of adolescents, young people, and women, implementing SRH projects
              aimed at preventing unintended pregnancies, gender-based and sexual violence, sexually transmitted
              diseases, maternal disabilities, and maternal deaths. RHIYW is committed to reinforcing the capacity of
              public institutions in providing high-quality, affordable sexual reproductive health (SRH) services.
            </p>

            {/* Mission */}
            <h3 className="font-weight-bold mb-2">Mission</h3>
            <p className="lead">
              To ensure that adolescents, young people, and women live healthy lives by implementing projects aimed at
              preventing unintended pregnancies, gender-based & sexual violence, sexually transmitted diseases, and
              maternal disabilities and maternal deaths. RHIYW applies high-impact interventions and innovations to save
              lives of adolescents, youth, and women.
            </p>

            {/* Values */}
            <h3 className="font-weight-bold mb-2">Values</h3>
            <p className="lead">
              The values upheld by RHIYW include:
              <ol className="list-decimal pl-5">
                <li>Gender equality</li>
                <li>Equity</li>
                <li>Accountability</li>
                <li>Partnership</li>
                <li>Transparency</li>
                <li>Sustainability</li>
                <li>Integrity</li>
                <li>Creativity & Innovation</li>
                <li>Excellence</li>
              </ol>
            </p>
            <br/>
            {/* Priorities */}
            <h2 className="font-weight-bolder mb-4">RHIYW's Priorities</h2>
            <p className="lead">
              RHIYW's priorities include:
              <ol className="list-decimal pl-5">
                <li>To reinforce the capacity of public institutions in providing high-quality, affordable SRH
                  services.
                </li>
                <li>Supporting public youth centers, youth corners, and other community spaces in providing
                  high-quality, confidential, and comprehensive SRH education and services.
                </li>
                <li>Strengthening strategies and programs to enhance women’s health during pregnancy and childbirth,
                  especially for adolescent girls and young women.
                </li>
                <li>Implementing innovative interventions for youth-friendly SRH services in rural areas.</li>
                <li>Conducting research, monitoring, and evaluation programs to inform SRH and maternal health policies
                  and practices.
                </li>
                <li>Introducing digital tools to improve access to SRH and maternal health services.</li>
                <li>Providing technical assistance to the Ministry of Health in reducing maternal mortality from unsafe
                  abortion, sexual violence, and STDs.
                </li>
                <li>Supporting the creation of evidence-based clinical guidelines for various SRH services.</li>
                <li>Enhancing the effectiveness of Isange One Stop Centers and public health facilities in supporting
                  GBV victims.
                </li>
                <li>Advocating for family planning services, with a focus on long-term contraceptive methods.</li>
                <li>Ensuring availability of high-quality sexual and maternal health commodities in public health
                  facilities.
                </li>
                <li>Expanding SRH services in rural areas served by faith-based health facilities.</li>
                <li>Improving accessibility of SRH services for high-risk youth groups, including teen mothers and sex
                  workers.
                </li>
                <li>Strengthening leadership capabilities of young people in SRH programs, especially for adolescent
                  girls and young women.
                </li>
                <li>Enhancing the capacity of community health workers in delivering reproductive health education and
                  services.
                </li>
                <li>Implementing strategies to combat gender discrimination and sexual violence.</li>
                <li>Supporting public health facilities with the necessary tools and resources for SRH services.</li>
              </ol>
            </p>
          </div>
        </div>


      </div>
    </section>

    <section className="section section-lg pt-6">
      <div className="container flex flex-col gap-8">
        <div className="row row-grid align-items-center">
          <div className="col-12">
            <h2 className="font-weight-bolder mb-4">Partners</h2>
            <p className="lead">
              <div className="row">
                <div className="col">
                  <ul className="list-disc pl-5">
                    <li>Rwanda Ministry of Health (MoH)</li>
                    <li>Rwanda Biomedical Center (RBC)</li>
                    <li>Imbuto Foundation</li>
                    <li>Ministry of Youth and Art</li>
                    <li>Districts / District Joint Action Development Forum (JADF)</li>
                    <li>Bixby center, University of California Berkeley</li>
                  </ul>
                </div>
                <div className="col">
                  <ul className="list-disc pl-5">
                    <li>The David and Lucile Packard Foundation</li>
                    <li>Rwanda Society of Obstetricians and Gynecologists (RSOG)</li>
                    <li>Royal College of Obstetricians and Gynecology (RCOG)</li>
                    <li>IPAS Africa alliance</li>
                    <li>KASHA</li>
                    <li>Preston Werner Ventures</li>
                  </ul>
                </div>
                <div className="col">
                  <ul className="list-disc pl-5">
                    <li>Venture Strategies for Health and Development (VSHD)</li>
                    <li>Hesperian Health Guides</li>
                    <li>Great Lakes Initiatives for Human Rights and Development (GLIHD)</li>
                    <li>Action of Men for Gender Equality in Rwanda (AMEGERWA)</li>
                    <li>Nurses and Midwives professional organizations</li>
                  </ul>
                </div>
              </div>
            </p>
          </div>
        </div>
        <br/>
        <div className="row gap-8">

          <div className="col-12 ">
            <h2 className="font-weight-bolder mb-4">Our Approach</h2>
            <p className="lead">
              RHIYW implements a variety of strategies to effectively promote sexual
              reproductive health services.
              <ol className="list-decimal pl-5 mt-2">
                <li>Capacity Building; Training Healthcare Providers and Organizational Development.</li>
                <li>Research and Evidence-Based Practices; Conduct Research and Data Utilization.</li>
                <li>Establishing strong partnerships and supporting public health institutions in project
                  implementation.
                </li>
                <li>Focus on hard-to-reach populations, including marginalized groups, adolescents, and low-income
                  communities, to ensure equitable access to SRH services.
                </li>
                <li>Community Engagement and Participation; including Promoting adolescents and youth participation.
                </li>
                <li>Technology and Innovation; Digital Health Solutions.</li>
                <li>Comprehensive Education and Awareness and Implementing peer education activities.</li>
                <li>Employing a client-centered approach.</li>
                <li>Prioritizing confidentiality and privacy.</li>
                <li>Integrated Service Delivery; Holistic Care Models and Youth-Friendly Services.</li>
                <li>Empowering health care providers in service, including nurses, midwives, medical doctors, and
                  others.
                </li>
                <li>Advocacy and Policy Engagement.</li>
                <li>Collaborating with national and international public health research institutions.</li>
                <li>Collaboration with health care professional organizations.</li>
                <li>Support Services; Counseling, Support, and referrals.</li>
                <li>Implement robust monitoring and evaluation frameworks to assess program effectiveness, gather
                  feedback, and make data-informed adjustments.
                </li>
                <li>Train staff to provide culturally sensitive services that respect the diverse backgrounds of
                  clients.
                </li>
              </ol>
            </p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default SecondPage
